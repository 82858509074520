import HomePage from "./Home";
import BackToTop from "./backToTop";

export default function Home({handleLanguageChange}) {
  return (
    <>
      <HomePage handleLanguageChange={handleLanguageChange} />
      <BackToTop />
    </>
  );
}
